.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  /* background-color: #282c34; */
  background-image: linear-gradient(blue, lightblue);
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  color: white;
}
hr{
  width: 75% !important;
}
.title-header{
  font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
}
.contact-btn{
  color: white;
  font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
}
.body-div{
  background-color: lightblue;
  padding-bottom: 10px;
}
#contact{
  background-color: lightblue;
}
.footer-div{
  height: 50px;
  color: white;
  font-family: Arial, Helvetica, sans-serif;
  background-image: linear-gradient(lightblue, blue);
}
.list-margin{
  margin-left: -30px;;
}
img
{
   width: 100%;
   margin: auto;
}
.email-card {
  width: 22rem !important;
}
.description-card {
  margin-top: 10px;
  margin-bottom: 10px;
  width: 40%;
  padding: 0px;
}
.solid-spacing {
  justify-content: space-evenly !important;
}
.card-header {
  width: 100%;
}
.no-styling {
  list-style: none;
  margin-bottom: 0px;
}
.contact-card {
  width: 18rem;
  margin: 10px;
}
.no-bottom-padding {
  padding-bottom: 0px;
}
.transparent-bg{
  color: white;
  font-style: bold;
}
.carousel-control-prev, .carousel-control-next {
  color: black;
}
.carousel-caption {
  font-weight: bold;
  background-color: grey;
  border-radius: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding: 5px;
}
.double-list {
  display: flex;
  justify-content: center;
  justify-content: space-evenly;
}
.App-link {
  color: #61dafb;
}
.top-space {
  margin-top: 18px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}




@media only screen and (max-width: 991px) {
  .picturesss {
    flex-direction: column;
    align-items: center;
  }
  .col-6 {
    width: 90%;
  }
  .description-card {
    width: 90%;
  }
}